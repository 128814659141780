import React from "react";
import { graphql } from "gatsby";
import { Index } from "lunr";
import Layout from "../components/layout";
import Card from "../components/card";
import Searchbar from "../components/searchbar";

export const pageQuery = graphql`
	query {
		RecipeIndex
		recipes: allStrapiRecipe(
			filter: { Publish: { eq: true } }
			sort: { order: [DESC, DESC], fields: [Sorting, updatedAt] }
		) {
			edges {
				node {
					Name
					Description
					Coverphoto {
						publicURL
					}
					Slug
					styles {
						Name
						id
						Slug
					}
					categories {
						Name
						id
						Slug
					}
					strapiId
				}
			}
		}
	}
`;

// We can access the results of the page GraphQL query via the data props
const SearchPage = ({ data, location }) => {
	// We can read what follows the ?q= here
	// URLSearchParams provides a native way to get URL params
	// location.search.slice(1) gets rid of the "?"
	const params = new URLSearchParams(location.search.slice(1));
	const q = params.get("q") || "";

	// Prepare store from query result
	const store = {};
	data.recipes.edges.map((recipe, i) => {
		return (store[recipe.node.Slug] = recipe);
	});
	// console.log("Store:", store);

	// Lunr in action here
	const index = Index.load(data.RecipeIndex);
	console.log("Index:", index);

	let results = [];
	try {
		// Search is a lunr method
		let rawsearch = index.search(q);
		console.log("Search raw result:", rawsearch);
		results = rawsearch.map(({ ref }) => {
			// Map search results to an array of objects
			return {
				slug: ref,
				...store[ref],
			};
		});
		console.log("result:", results);
	} catch (error) {
		console.log(error);
	}
	return (
		<Layout title={q + `的食譜`}>
			<section className="py-5">
				<div className="flex flex-col-reverse lg:flex-row px-3 lg:px-10">
					<div className="lg:w-3/4">
						<h1 className="pt-5 lg:pt-0 text-3xl lg:text-3xl">
							{q}的食譜
						</h1>
					</div>
					<div className="item-right lg:w-1/4">
						<Searchbar initialQuery={q} />
					</div>
				</div>
			</section>
			<section>
				<div className="container mx-auto py-8 flex flex-col lg:flex-row lg:flex-wrap justify-center">
					{results.length ? (
						results.map((recipe) => {
							return <Card recipe={recipe} />;
						})
					) : (
						<p>暫時未有相關食譜</p>
					)}
				</div>
			</section>
		</Layout>
	);
};
export default SearchPage;
